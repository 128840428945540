import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "Add-Line-Items",
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      line_items: [{
        check_status: false,
        description: null,
        category: null,
        quantity: null,
        unit_net_amount: null,
        net_amount: null,
        tax_amount: null,
        total_amount: null
      }],
      categoryList: [],
      checkbox: false
    };
  },
  methods: {
    addLineItem: function addLineItem() {
      var obj = {
        check_status: false,
        description: null,
        category: null,
        quantity: null,
        unit_net_amount: null,
        net_amount: null,
        tax_amount: null,
        total_amount: null
      };
      this.line_items.push(obj);
    },
    deleteLineItem: function deleteLineItem(index) {
      this.line_items.splice(index, 1);
    }
  }
};